import React from 'react';
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import {message, Alert} from 'antd';
import qs from 'query-string';
import {useOutlet} from 'reconnect.js';
import Config from '../../../data.json';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom.js';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import LoginRequired from '../../Components/LoginRequired';
import PermissionRequired from '../../Components/PermissionRequired';

function trackVideoPlaybackStats(stats, onFinished) {
  const el = document.querySelector('mux-player');

  stats.played = 0;
  stats.start_time = 0;
  stats.finished = false;

  el.addEventListener('play', () => {
    console.log('---------------play');
    stats.start_time = el.currentTime * 1000; //new Date().getTime();
    console.log('play start_time', stats.start_time);
  });

  el.addEventListener('pause', () => {
    console.log('---------------pause');
  });

  el.addEventListener('timeupdate', (e) => {
    const now = el.currentTime * 1000; // new Date().getTime();
    const diff = now - stats.start_time;

    if (Math.abs(diff) < 1000) {
      stats.played = stats.played + diff;
      console.log(
        `[timeupdate] ${stats.start_time} / ${now} / ${diff} ==> ${stats.played}`,
      );
    } else {
      console.log(
        `[timeupdate] ${stats.start_time} / ${now} / ${diff} ==> (rejected)`,
      );
    }

    stats.start_time = now;

    if (stats.played >= (el.duration * 1000) / 1.5 && !stats.finished) {
      onFinished();
      stats.finished = true;
      console.log('---------------finished');
    }
  });
}

function VideoPage(props) {
  const params = qs.parse(props.location.search);
  const [user] = useOutlet('user');
  const [video, setVideo] = React.useState(null);
  const [playbackId, setPlaybackId] = React.useState(null);
  const [playbackToken, setPlaybackToken] = React.useState(null);
  const [product, setProduct] = React.useState(null);
  const videoPlaybackStatsRef = React.useRef({});
  const [permission, setPermission] = React.useState(true);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        const v = await JStorage.fetchOneDocument('video', {id: params.id});
        setVideo(v);

        if (v.permission === 'public' || !v.permission) {
          setPlaybackId(v?.playback_ids[0]?.id);
        } else if (v.permission === 'signed') {
          const resp = await ApiUtil.req(
            `${Config.apiHost}/mux/jwt?token=${user?.token}`,
            {
              method: 'POST',
              data: {video_id: v.id},
            },
          );

          setPlaybackId(resp.playback_id);
          setPlaybackToken(resp.mux_token);
        }

        const p = await JStorage.fetchOneDocument('product', {id: v.product});
        setProduct(p);

        // wait a little longer for mux library loading
        await AppActions.delay(600);

        if (user && !user.tmp) {
          const {total, results} = await JStorage.fetchDocuments(
            'study_hours',
            {
              user_id: user.sub,
            },
            ['-created'],
            {offset: 0, limit: 1},
          );

          let isPosted = false;

          const isQualifiedUser = (() => {
            if (user.data) {
              if (user.data.user_type === 'extra_member') {
                return true;
              } else if (user.data.user_type === 'member') {
                if (user.data.state === 4) {
                  return true;
                }

                return (
                  (user.data.substate === '4' || user.data.substate === '5') &&
                  user.data.state === 1
                );
              } else {
                return false;
              }
            }

            return false;
          })();

          if (total > 0) {
            isPosted = results[0].products.some(
              (productId) => productId === p.id,
            );
          }

          if (!isPosted && isQualifiedUser) {
            trackVideoPlaybackStats(videoPlaybackStatsRef.current, () => {
              if (p.non_publish_hour) {
                setTimeout(async () => {
                  try {
                    AppActions.setLoading(true);
                    const resp = await JStorage.fetchDocuments(
                      'video_attend',
                      {
                        product_id: p.id,
                        user_id: user.data?.owner,
                      },
                      null,
                      {offset: 0, limit: 1},
                    );

                    if (resp.total === 0) {
                      await JStorage.createDocument('video_attend', {
                        product_id: p.id,
                        user_id: user.sub,
                      });

                      message.success('獲得核發資格成功。');
                    }
                  } catch (err) {
                    console.warn(err);
                    message.error('獲得核發資格失敗。');
                  } finally {
                    AppActions.setLoading(false);
                  }
                }, 100);

                return;
              }

              setTimeout(async () => {
                try {
                  AppActions.setLoading(true);
                  await AppActionsEx.bulkwritePostTbaHours({
                    product_id: p.id,
                    users_id: [user.sub],
                  });
                  message.success('進修時數發放成功。');
                } catch (err) {
                  console.warn(err);
                  message.error('進修時數發放失敗。');
                } finally {
                  AppActions.setLoading(false);
                }
              }, 100);
            });
          }
        }
      } catch (ex) {
        console.warn('VideoPage err', ex);

        if (ex.response?.error === 'video_permission_denied') {
          setPermission(false);
        }
      }
      AppActions.setLoading(false);
    }

    if (params.id) {
      fetchData();
    }
  }, [params.id, user]);

  if (!user) {
    return <LoginRequired />;
  }

  if (!permission) {
    return <PermissionRequired />;
  }

  return (
    <Wrapper>
      <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/@mux/mux-player"></script>
      </Helmet>

      {product && (
        <>
          <div className="container">
            <h2 className="title">{product.name}</h2>

            <div className="information">
              {product.tba_hours && product.tba_hours > 0 ? (
                product.non_publish_hour ? (
                  <Alert
                    showIcon
                    type="warning"
                    message={
                      <div>
                        觀看北律雲專錄課程影片，敬請留意
                        <span style={{color: '#f03e3e'}}>「請勿關閉網頁」</span>
                        ，觀看完畢後，將由公會人員後續核發進修時數
                        <span style={{paddingLeft: 3, paddingRight: 3}}>
                          {product.tba_hours}
                        </span>
                        小時。
                      </div>
                    }
                  />
                ) : (
                  <Alert
                    showIcon
                    type="warning"
                    message={
                      <div>
                        觀看北律雲專錄課程影片，敬請留意
                        <span style={{color: '#f03e3e'}}>「請勿關閉網頁」</span>
                        ，觀看完畢後，即可核發進修時數
                        <span style={{paddingLeft: 3, paddingRight: 3}}>
                          {product.tba_hours}
                        </span>
                        小時。
                      </div>
                    }
                  />
                )
              ) : null}
            </div>

            {video && playbackId && (
              <div className="content">
                {playbackToken ? (
                  <mux-player
                    stream-type="on-demand"
                    title={video.name}
                    playback-id={playbackId}
                    playback-token={playbackToken}></mux-player>
                ) : (
                  <mux-player
                    stream-type="on-demand"
                    title={video.name}
                    playback-id={playbackId}></mux-player>
                )}
              </div>
            )}
          </div>
          <div className="container">
            <div className="content">{video?.description}</div>
            <div className="content">{product.spec}</div>
            <div className="content">{product.intro}</div>
            <div className="content">{product.remark}</div>
            {video && video.materials && video.materials.length > 0 && (
              <div className="content">
                <h3 className="title">講義下載</h3>
                {video.materials.map((material, index) => (
                  <div key={`material-${index}`} className="item">
                    <a target="_blank" href={material.src} rel="noreferrer">
                      {material.name}
                    </a>
                  </div>
                ))}
              </div>
            )}
            {video?.show_richtext && (
              <div
                dangerouslySetInnerHTML={{__html: video?.richtext?.html}}
                style={{margin: '10px auto'}}
              />
            )}
          </div>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  padding-top: var(--topNavBarHeight);
  background-color: var(--pageBackgroundColor);
  min-height: 100vh;

  & > .container {
    margin: 30px auto;
    max-width: 1024px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 10px;

    & > h2 {
      margin-bottom: 10px;
      font-weight: 600;
      color: var(--primaryColor);
    }

    & > .information {
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    & > .content {
      margin: 10px auto;
      font-size: 1rem;
      line-height: 2rem;

      & > .title {
        margin: 10px 0px;
        color: var(--secondColor);
      }
    }
  }
`;

export default VideoPage;
