import React from 'react';
import styled from 'styled-components';
import {getOutlet} from 'reconnect.js';
import {Button, Result, Space} from 'antd';
import Config from '../../../data.json';
import * as AppActions from '../../AppActions';

const PermissionRequired = (options) => {
  const {admin, layout} = options;

  return (
    <Wrapper admin={admin}>
      <Result
        icon={<img src={Config.loginRequiredImage} alt="permission-denied" />}
        title={'您沒有瀏覽權限'}
        subTitle={'此頁面需要權限，方能瀏覽'}
        extra={
          <Space direction="vertical">
            <Button
              onClick={() => {
                AppActions.navigate('/');
              }}>
              回到首頁
            </Button>
          </Space>
        }
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: ${({admin}) => (admin ? '100vh;' : 'var(--contentMinHeight);')}
  padding-top: var(--topNavBarHeight);
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    width: 150px
  }
`;

export default PermissionRequired;
